<template>
  <div class="pay">
    <div class="pay-content">
      <img class="pay-img" :src="imgSrc" alt="" v-if="showCode" />
      <img
        class="pay-success"
        src="@/assets/pay-success.png"
        alt=""
        srcset=""
        v-if="!showCode"
      />
      <h2 v-if="!showCode">{{ "Thanh toán thành công" }}</h2>
      <div class="pay-btn">
        <van-button
              style="width: 100%; border-radius: 0.5rem"
              color="linear-gradient(to right, #8E85D6, #AEA6F6)"
              @click="handleBack()"
              >BACK</van-button
            >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      showCode: true,
      imgSrc: "",
      order_no: "",
      type: "",
      time: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.imgSrc = this.$route.query.redirect_url;
    this.order_no = this.$route.query.order_no;
    this.type = this.$route.query.type;
    this.time = setInterval(() => {
      this.getPayOrderInfo();
    }, 5000);

    setTimeout(() => {
      clearInterval(this.time);
    }, 5000 * 12);
  },
  mounted() {},
  methods: {
    getPayOrderInfo() {
      let params = {
        order_no: this.order_no,
        type: this.type,
      };
      this.$http.post("/apispay/h5pay/queryOrder", params).then((res) => {
        if (res.code === 200) {
          if (res.data.pay_status !== "0") {
            if (this.time) {
              clearInterval(this.time);
            }
            this.showCode = false;
            this.$Msg(res.msg);
          }
        } else {
          if (this.time) {
            clearInterval(this.time);
          }
          this.$Msg(res.msg);
        }
      });
    },
    handleBack() {
      if (this.time) {
        clearInterval(this.time);
      }
      // this.$router.go(-1)
      window.close()
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.pay {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pay-img {
  width: 80%;
}
.pay-success {
  width: 250px;
}
.pay-btn {
  width: 80%;
  height: 30px;
}
h2 {
  margin: 48px 0;
}

@media screen and (max-width: 1000px) {
}
</style>
